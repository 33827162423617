<template>
  <div class="bg-white shadow-xl">
    <table class="w-full border-collapse hidden md:table">
      <thead class="text-xs">
      <th class="text-left uppercase w-32">{{ $t('time') }}</th>
      <th class="uppercase">{{ $t('result.group') }}</th>
      <th class="uppercase" colspan="7">{{ $t('result.event') }}</th>
      </thead>
      <tbody>
        <template v-for="(line, index) in tipData.matches" :key="`timetable-${index}`">
          <tr v-if="groups.includes(line.groupId)">
            <td class="text-center">
              <span class="text-xs">{{ dateFormat(line.date) }}</span><br/><span class="font-bold text-sm">{{ hourFormat(line.date) }}</span>
            </td>
            <td style="width: 50px" class="text-sm">{{ tipData.groups[line.groupId.startsWith('azubi') ? 'azubi' : 'profi'][line.groupId][$i18n.locale] }}</td>
            <td class="text-right font-bold"
                :class="{ 'text-red-700': line.matchIsFinished && line.results.scoring?.pointsTeam1 > line.results.scoring?.pointsTeam2, 'text-slate-500': line.matchIsFinished && line.results.scoring?.pointsTeam1 < line.results.scoring?.pointsTeam2 }"
                v-html="tipData.teams[line.teamId1].teamName"></td>
            <td class="has-logo"><img :src="tipData.teams[line.teamId1].teamFlag" v-if="tipData.teams[line.teamId1].teamFlag"></td>
            <td style="width: 20px" class="text-3xl text-slate-500 font-bold text-right" v-html="line.matchIsFinished ? line.results.scoring?.pointsTeam1 : '-'"></td>
            <td class="font-xl font-serif" style="width: 10px">:</td>
            <td style="width: 20px" class="text-3xl text-slate-500 font-bold text-left" v-html="line.matchIsFinished ? line.results.scoring?.pointsTeam2 : '-'"></td>
            <td class="has-logo"><img :src="tipData.teams[line.teamId2].teamFlag" v-if="tipData.teams[line.teamId2].teamFlag"></td>
            <td class="text-left font-bold"
                :class="{ 'text-red-700': line.matchIsFinished && line.results.scoring?.pointsTeam1 < line.results.scoring?.pointsTeam2, 'text-slate-500': line.matchIsFinished && line.results.scoring?.pointsTeam1 > line.results.scoring?.pointsTeam2 }"
                v-html="tipData.teams[line.teamId2].teamName"></td>
          </tr>
        </template>
      </tbody>
    </table>
    <table class="w-full border-collapse md:hidden">
      <thead class="text-xs">
        <th colspan="2" class="text-left uppercase">{{ $t('time') }}</th>
        <th colspan="6" class="uppercase text-left">{{ $t('result.event') }}</th>
      </thead>
      <tbody>
      <template v-for="(line, index) in tipData.matches" :key="`timetable-${index}`">
        <tr v-if="groups.includes(line.groupId)">
          <td class="text-left pb-0 text-slate-400"
              :class="{ 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !==  0 }">
            <span class="text-xs">{{ dateFormat(line.date) }}</span>, <span class="font-bold text-xs">{{ hourFormat(line.date) }}</span>
          </td>
          <td style="width: 25px; padding: 0 4px"
              :class="{ 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"><img :src="tipData.teams[line.teamId1].teamFlag" :alt="''" v-if="tipData.teams[line.teamId1].teamFlag"></td>
          <td class="font-serif"
              :class="{ 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"
              style="width: 10px">:</td>
          <td style="width: 25px; padding: 0 4px"
              :class="{ 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"><img :src="tipData.teams[line.teamId2].teamFlag" :alt="''" v-if="tipData.teams[line.teamId2].teamFlag"></td>
          <td class="text-xs font-bold pb-0 text-right text-slate-400"
              :class="{ 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }">{{ tipData.groups[line.groupId.startsWith('azubi') ? 'azubi' : 'profi'][line.groupId][$i18n.locale] }}</td>
        </tr>
        <tr v-if="groups.includes(line.groupId)" class="text-sm">
          <td class="text-left font-bold"
              :class="{ 'text-red-700': line.matchIsFinished && line.results.scoring?.pointsTeam1 > line.results.scoring?.pointsTeam2, 'text-slate-500': line.matchIsFinished && line.results.scoring?.pointsTeam1 < line.results.scoring?.pointsTeam2, 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"
              v-html="tipData.teams[line.teamId1].teamShort"></td>
          <td
              :class="{ 'text-red-700': line.matchIsFinished && line.results.scoring?.pointsTeam1 > line.results.scoring?.pointsTeam2, 'text-slate-500': line.matchIsFinished && line.results.scoring?.pointsTeam1 < line.results.scoring?.pointsTeam2, 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"
              class="font-bold text-right text-2xl"
              v-html="line.matchIsFinished ? line.results.scoring?.pointsTeam1 : '-'"></td>
          <td class="font-serif"
              :class="{ 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"
              style="width: 10px">:</td>
          <td
              class="font-bold text-left text-2xl"
              :class="{ 'text-red-700': line.matchIsFinished && line.results.scoring?.pointsTeam1 < line.results.scoring?.pointsTeam2, 'text-slate-500': line.matchIsFinished && line.results.scoring?.pointsTeam1 > line.results.scoring?.pointsTeam2, 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"
              v-html="line.matchIsFinished ? line.results.scoring?.pointsTeam2 : '-'"></td>
          <td class="text-right font-bold"
              :class="{ 'text-red-700': line.matchIsFinished && line.results.scoring?.pointsTeam1 < line.results.scoring?.pointsTeam2, 'text-slate-500': line.matchIsFinished && line.results.scoring?.pointsTeam1 > line.results.scoring?.pointsTeam2, 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"
              v-html="tipData.teams[line.teamId2].teamShort"></td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'TimetableComponent',
  components: {},
  props: {
    tipData: Object,
    groups: Array
  },
  data() {
    return {}
  },
  methods: {
    dateFormat: function (input) {
      const date = new Date(input);
      return `${(`00${date.getDate()}`).slice(-2)}.${(`00${date.getMonth() + 1}`).slice(-2)}.${date.getFullYear()}`;
    },
    hourFormat: function (input) {
      const date = new Date(input);
      return `${(`00${date.getHours()}`).slice(-2)}:${(`00${date.getMinutes()}`).slice(-2)}`;
    }
  },
  created() {},
  mounted() {},
  unmounted() {}
};
</script>

<style lang="scss">
td {
  &.has-logo, &:last-child.has-logo, &:first-child.has-logo {
    width: 50px;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
