<template>
  <div class="relative grid grid-cols-1 md:grid-cols-2 gap-x-10 md:gap-x-20">
    <template v-for="groupName in groups" :key="groupName">
      <div class="bg-white shadow-2xl mb-10" v-if="tipData.groups && tipData.groups[tournament][groupName].generateStandings && tipData.groups[tournament][groupName].standings && tipData.groups[tournament][groupName].standings.length">
        <div class="font-black uppercase p-5 text-xl">{{ tipData.groups[tournament][groupName][$i18n.locale] }}</div>
        <table class="w-full border-collapse">
          <thead>
          <th class="text-xs">&nbsp;</th>
          <th class="text-xs">&nbsp;</th>
          <th class="text-xs text-left" v-html="$t('result.team')"></th>
          <th class="text-xs" v-html="$t('result.tableGames')"></th>
          <th class="text-xs hidden md:table-cell" v-html="$t('result.tableDiff')"></th>
          <th class="text-xs text-right" v-html="$t('result.tablePoints')"></th>
          </thead>
          <tbody>
          <tr v-for="(line, index) in tipData.groups[tournament][groupName].standings" :key="`grp-${index}`" class=" text-sm md:text-default">
            <td>{{ index + 1 }}</td>
            <td class="has-logo" style="width: 40px"><img
                :src="tipData.teams[line.teamId].teamFlag"
                v-if="tipData.teams[line.teamId]"></td>
            <td class="font-bold">
              {{ tipData.teams[line.teamId].teamName }}
            </td>
            <td class="text-center" v-html="line.games"></td>
            <td class="text-center hidden md:table-cell">{{ `${line.goals} : ${line.goalsAgainst}` }}</td>
            <td class="text-right font-bold" v-html="line.points"></td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'TableComponent',
  components: {},
  props: {
    tipData: Object,
    groups: Array,
    tournament: {
      type: String,
      default: 'profi'
    },
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  unmounted() {}
};
</script>

<style lang="scss">
</style>
