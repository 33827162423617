<template>
  <footer class="text-white">
    <div class="bg-red-700" :class="{ 'py-10': showPartner, 'pb-2': !showPartner }">
      <div class="container-small px-5 xl:px-0" v-if="showPartner">
        <div class="font-serif font-black text-2xl md:text-4xl uppercase mb-10">
          {{ $t('footer.partners') }}
        </div>
        <div class="flex flex-wrap md:flex-nowrap justify-center md:justify-between gap-5 items-center">
          <div class="flex-initial">
            <a href="https://adidas.com" target="_blank"><img src="@/assets/images/sponsor/adidas.webp" class="sponsor" alt="Adidas"></a>
          </div>
          <div class="flex-initial">
            <a href="https://www.mercedes-benz-bank.de" target="_blank"><img src="@/assets/images/sponsor/mbbank.webp" class="sponsor" alt="Mercedes-Benz Bank"></a>
          </div>
          <div class="flex-initial">
            <a href="https://www.allianz.de" target="_blank"><img src="@/assets/images/sponsor/allianz.webp" class="sponsor" alt="Allianz"></a>
          </div>
          <div class="flex-initial">
            <a href="https://gazi.de" target="_blank"><img src="@/assets/images/sponsor/gazi.webp" class="sponsor" alt="GAZI"></a>
          </div>
          <div class="flex-initial">
            <a href="https://www.sindelfingen.de/" target="_blank"><img src="@/assets/images/sponsor/sindelfingen.webp" class="sponsor" alt="Stadt Sindelfingen"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="container-small px-5 py-10 xl:px-0">
      <div class="flex gap-5 items-center justify-center">
        <router-link :to="{ name: 'imprint', params: { locale: $i18n.locale } }" class="hover:text-red-500 transition-all">{{ $t('footer.imprint') }}</router-link>
        <router-link :to="{ name: 'dataprotection', params: { locale: $i18n.locale } }" class="hover:text-red-500 transition-all">{{ $t('footer.privacy') }}</router-link>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'FooterComponent',
  components: {},
  props: {
    isHome: Boolean,
  },
  data() {
    return {
      visible: false,
      showPartner: true
    }
  },
  methods: {},
  mounted() {
    //
    console.log(this.$i18n.locale);
  },
  unmounted() {
    //
  }
};
</script>

<style lang="scss">
footer {
  @apply bg-neutral-800;

  .sponsor {
    height: 40px;
    width: auto;
    max-width: none;
  }
}
</style>
