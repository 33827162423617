<template>
  <header class="text-white">
    <div class="container" v-scroll:in="() => visible = true">
      <div class="relative header__keyvisual">
        <div class="header__fade header__fade--left"></div>
        <div class="header__fade header__fade--right"></div>
        <div class="header__logo container-small px-5 xl:px-0">
          <div class="fade-in delay-300" :class="{ 'view': visible }"><router-link :to="{ name: 'home', params: { locale: $i18n.locale } }"><img src="/img/MBJC-2025_Logo_RGB_negativ.svg" alt="Mercedes-Benz Junior Cup 2024"></router-link></div>
          <div class="pt-20" v-if="showDate">
            <div class="font-serif text-white text-4xl md:text-6xl uppercase fade-in delay-700" :class="{ 'view': visible }" v-html="$t('juniorCup.date')"></div>
            <div class="font-serif uppercase text-2xl md:text-4xl fade-in delay-900" :class="{ 'view': visible }" v-html="$t('juniorCup.location')"></div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: 'HeaderComponent',
  components: {},
  props: {
    isHome: Boolean,
  },
  data() {
    return {
      visible: false,
      showDate: true
    }
  },
  methods: {},
  mounted() {
    //
    console.log(this.$i18n.locale);
  },
  unmounted() {
    //
  }
};
</script>

<style lang="scss">
header {
  z-index: 500;
  background: #000;
  overflow: hidden;
}
.header {
  &__fade {
    @apply absolute top-0 bottom-0 opacity-0 xl:opacity-100;
    transition: opacity .3s;
    width: 150px;
    &--left {
      left: 0;
      background: linear-gradient(90deg, rgba(#000, 1), rgba(#000, 0));
    }
    &--right {
      right: 0;
      background: linear-gradient(-90deg, rgba(#000, 1), rgba(#000, 0));
    }
  }
  &__keyvisual {
    @apply h-[50dvh] md:h-auto;
    background: url('@/assets/images/MBJC2025_keyvisual_desktop_1920x647px_optimized.jpg') center center no-repeat;
    background-size: cover;
  }
  &__logo {
    @apply pt-10 md:pt-20 pb-20 relative;
    img {
      @apply w-[563px];
    }
  }
}
@media (max-width: 767px) {
  .header {
    &__keyvisual {
      background: url('@/assets/images/MBJC2025_keyvisual_mobile_780x975px.jpg') top left no-repeat;
      background-size: cover;
    }
  }
}
</style>
